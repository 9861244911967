export const getModalPosition = (
  position: 'top' | 'bottom' | 'left' | 'right',
  alignment: 'top' | 'left' | 'right' | 'center' | undefined,
  margin: {
    top?: number
    left?: number
  },
  containerHeight: number,
  containerWidth: number,
  rect: {
    top: number
    left: number
    bottom: number
    right: number
  },
) => {
  const alignmentObject = {
    left: rect.left,
    right: rect.right! - containerWidth,
    top: window.scrollY + rect.top! - 40,
    center: window.scrollY + rect.top! - containerHeight / 2,
  }

  const marginTop = margin?.top ?? 0
  const marginLeft = margin?.left ?? 0

  switch (position) {
    case 'top':
      return {
        top: window.scrollY + (rect.top! - containerHeight - 30) + marginTop,
        left: alignmentObject[alignment!] + marginLeft,
      }
    case 'bottom':
      return {
        top: window.scrollY + (rect.top! + 40) + marginTop,
        left: alignmentObject[alignment!] + marginLeft,
      }
    case 'right':
      return {
        top: alignmentObject[alignment!] + marginTop,
        left: rect.right! + marginLeft,
      }
    case 'left':
      return {
        top: alignmentObject[alignment!] + marginTop,
        left: rect.left! - containerWidth - 10 + marginLeft,
      }
  }
}
