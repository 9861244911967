import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import { IntercomService } from '@shared/services/Intercom'
import { PatientMixpanel } from '@shared/utils/patientMixpanel'
import userPool from '@shared/utils/userPool'

type AuthState = {
  idToken?: string
  accessToken?: string
  refreshToken?: string
  isAdmin?: boolean
  isAuthenticated: boolean
  authError?: string
  newPasswordRequired: boolean
  hasSignedUp: boolean
  email?: string
  forgotPasswordUsername?: string
  forgotPasswordSuccess: boolean
  cognitoUser?: CognitoUser
  firstName?: string
  lastName?: string
  isPracticeUser: boolean
  username: string
  trackingId?: string
}

type AuthActions = {
  authenticateUser: (loginEmail: string, loginPassword: string) => void
  resetPassword: (newPassword: string) => void
  forgotPasswordSendCode: (username: string) => void
  forgotPasswordConfirm: (code: string, newPassword: string) => void
  logout: () => void
  getUser: (loginEmail: string) => CognitoUser
  forgotPasswordSendCodeIsLoading: boolean
  authenticationLoading: boolean
  getAuthState: (userSession: CognitoUserSession) => {
    username: string
    idToken: string
    accessToken: string
    refreshToken: string
    email: string
    isAdmin: boolean
    firstName: string
    lastName: string
  }
  setAuthState: (authState: {
    idToken?: string
    accessToken?: string
    refreshToken?: string
    email?: string
    isAdmin?: boolean
    isAuthenticated?: boolean
    authError?: string
    firstName?: string
    lastName?: string
    isPracticeUser?: boolean
    username?: string
    forgotPasswordSuccess?: boolean
    trackingId?: string
    forgotPasswordSendCodeIsLoading?: boolean
    authenticationLoading?: boolean
  }) => void
  getIsPracticeUser: () => boolean
  refreshSession: () => Promise<void>
}

const intercomService = new IntercomService()

export const useAuth = create<AuthState & AuthActions>()(
  devtools(
    persist(
      (set, get) => ({
        idToken: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        email: '',
        isAuthenticated: false,
        isAdmin: false,
        newPasswordRequired: false,
        hasSignedUp: false,
        forgotPasswordUsername: '',
        forgotPasswordSuccess: false,
        forgotPasswordSendCodeIsLoading: false,
        authenticationLoading: false,
        cognitoUser: undefined,
        firstName: '',
        lastName: '',
        isPracticeUser: false,
        username: '',
        trackingId: '',
        getUser: (username) => {
          return new CognitoUser({
            Username: username,
            Pool: userPool(get().getIsPracticeUser()),
          })
        },
        forgotPasswordSendCode: (username) => {
          const user = get().getUser(username)
          if (!user) {
            return
          }
          get().setAuthState({ forgotPasswordSendCodeIsLoading: true })
          user.forgotPassword({
            onSuccess: function () {
              get().setAuthState({
                authError: undefined,
                forgotPasswordSendCodeIsLoading: false,
              })
            },
            onFailure: function (err) {
              // TODO: Send to Sentry or other logging service
              get().setAuthState({ forgotPasswordSendCodeIsLoading: false })
              get().setAuthState({
                authError: err.message,
                forgotPasswordSendCodeIsLoading: false,
              })
            },
            inputVerificationCode: function () {
              set({
                forgotPasswordUsername: username,
                authError: undefined,
                forgotPasswordSendCodeIsLoading: false,
              })
            },
          })
        },
        forgotPasswordConfirm: (code, newPassword) => {
          const username = get().forgotPasswordUsername
          if (!username) {
            return
          }
          const user = get().getUser(username)
          if (!user) {
            return
          }
          user.confirmPassword(code, newPassword, {
            onSuccess() {
              set({
                forgotPasswordUsername: '',
                forgotPasswordSuccess: true,
                authError: undefined,
              })
            },
            onFailure(err) {
              // TODO: Send to Sentry or other logging service
              get().setAuthState({
                authError: err.message,
              })
            },
          })
        },
        resetPassword: (newPassword) => {
          const user = get().cognitoUser
          if (!user) {
            // Decide to handle this situation
            // console.log('A user is required to reset your password')
            return
          }
          user.completeNewPasswordChallenge(
            newPassword,
            {},
            {
              onSuccess: async (userSession) => {
                const isPracticeUser = get().getIsPracticeUser()
                const { username, idToken, accessToken, refreshToken, email, isAdmin, firstName, lastName } =
                  get().getAuthState(userSession)
                get().setAuthState({
                  username,
                  idToken,
                  accessToken,
                  refreshToken,
                  email,
                  isAdmin,
                  isAuthenticated: true,
                  isPracticeUser,
                  firstName,
                  lastName,
                })
              },
              onFailure: () => {
                // TODO: Send to Sentry or other logging service
                // console.error('completeNewPasswordChallenge onFailure', err)
              },
            },
          )
        },
        authenticateUser: (loginEmail, loginPassword) => {
          get().setAuthState({ authenticationLoading: true })
          const user = get().getUser(loginEmail)

          const authDetails = new AuthenticationDetails({
            Username: loginEmail,
            Password: loginPassword,
          })

          user.authenticateUser(authDetails, {
            onSuccess: (userSession) => {
              const { username, idToken, accessToken, refreshToken, email, isAdmin, firstName, lastName } =
                get().getAuthState(userSession)
              get().setAuthState({
                username,
                idToken,
                accessToken,
                refreshToken,
                email,
                isAdmin,
                isAuthenticated: true,
                firstName,
                lastName,
                isPracticeUser: get().getIsPracticeUser(),
                authenticationLoading: false,
              })
              intercomService.bootIntercom(firstName, lastName, email, 'Practitioner')
              window.location.assign('/practice/dashboard')
            },
            onFailure: (err) => {
              // eslint-disable-next-line no-console
              console.error('onFailure', err)
              get().setAuthState({
                authError: err.message,
                authenticationLoading: false,
              })
            },
            newPasswordRequired: (userAttributes) => {
              get().setAuthState({ authenticationLoading: false })
              const { ['custom:isAdmin']: isAdmin } = userAttributes
              set({
                email: loginEmail,
                newPasswordRequired: true,
                cognitoUser: user,
                hasSignedUp: false,
                isAdmin: Boolean(isAdmin),
              })
            },
          })
        },
        getAuthState: (userSession: CognitoUserSession) => {
          const idToken = userSession.getIdToken().getJwtToken()
          const accessToken = userSession.getAccessToken().getJwtToken()
          const refreshToken = userSession.getRefreshToken().getToken()
          const idTokenPayload = userSession.getIdToken().payload
          const {
            email,
            ['custom:isAdmin']: isAdmin,
            given_name: firstName,
            family_name: lastName,
            sub: username,
          } = idTokenPayload

          return {
            username,
            idToken,
            accessToken,
            refreshToken,
            email: email as string,
            isAdmin: Boolean(isAdmin),
            firstName,
            lastName,
          }
        },
        setAuthState: (authState) => {
          set(authState)
        },
        logout: () => {
          const isPractitioner = get().getIsPracticeUser()
          if (!isPractitioner) {
            window.location.assign('/patient/login')
          } else {
            const username = get().username
            if (username) {
              PatientMixpanel.reset()
              const currentUser = get().getUser(username)
              currentUser.getSession(() => {
                currentUser.globalSignOut({
                  onSuccess: () => {
                    intercomService.shutdownIntercom()
                  },
                  onFailure: () => null,
                })
              })
            }
            window.location.assign('/practice/login')
          }
          set({
            username: '',
            idToken: undefined,
            accessToken: undefined,
            refreshToken: undefined,
            isAuthenticated: false,
            authError: undefined,
            newPasswordRequired: false,
            hasSignedUp: false,
            email: '',
            firstName: '',
            lastName: '',
            forgotPasswordUsername: '',
            forgotPasswordSuccess: false,
            isAdmin: false,
            trackingId: '',
          })
        },
        getIsPracticeUser: () => window.location.pathname.startsWith('/practice'),
        refreshSession: () =>
          new Promise((resolve, reject) => {
            const refreshToken = get().refreshToken
            if (refreshToken) {
              const token = new CognitoRefreshToken({ RefreshToken: refreshToken })
              const username = get().username
              if (username) {
                const currentUser = get().getUser(username)
                // https://github.com/aws-amplify/amplify-js/blob/main/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1386
                currentUser.refreshSession(token, (err: Error | null, session: CognitoUserSession) => {
                  if (err) {
                    get().logout()
                    return
                  }
                  const { username, idToken, accessToken, refreshToken, email, isAdmin, firstName, lastName } =
                    get().getAuthState(session)

                  get().setAuthState({
                    username,
                    idToken,
                    accessToken,
                    refreshToken,
                    email,
                    firstName,
                    lastName,
                    isAdmin,
                    isAuthenticated: true,
                    isPracticeUser: true,
                  })
                  intercomService.bootIntercom(firstName, lastName, email, 'Practitioner')
                  resolve()
                })
              } else {
                reject()
              }
            }
          }),
      }),
      {
        name: 'auth',
        partialize: (state) => ({
          username: state.username,
          isAuthenticated: state.isAuthenticated,
          isAdmin: state.isAdmin,
          firstName: state.firstName,
          lastName: state.lastName,
          idToken: state.idToken,
          accessToken: state.accessToken,
          refreshToken: state.refreshToken,
          forgotPasswordUsername: state.forgotPasswordUsername,
          email: state.email,
          isPracticeUser: state.isPracticeUser,
          trackingId: state.trackingId,
        }),
      },
    ),
  ),
)
